.ellipseTop {
  position: absolute;
  left: 20.61%;
  right: 14.17%;
  top: 24.29%;
  bottom: 36.58%;

  background: radial-gradient(50% 50% at 50% 50%, rgba(171, 33, 236, 0.5) 0%, rgba(171, 33, 236, 0) 100%);
  filter: blur(150px);
}

.ellipseMiddle {
  position: absolute;
  left: 66.25%;
  right: 6.53%;
  top: 43.78%;
  bottom: 17.09%;

  background: radial-gradient(50% 50% at 50% 50%, rgba(45, 104, 255, 0.5) 0%, rgba(171, 33, 236, 0) 100%);
  filter: blur(150px);
}

.ellipseBottom {
  position: absolute;
  left: 72.22%;
  right: 0.56%;
  top: 52.77%;
  bottom: 2.1%;

  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 210, 255, 0.5) 0%, rgba(171, 33, 236, 0) 100%);
  filter: blur(150px);
}