@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Rubik&display=swap');

/* ========================== font guide
* font-family: 'Barlow', sans-serif; // base font
* font-family: 'Chango', cursive; // effect font
* font-family: 'Source Sans Pro', sans-serif;  // clean font
*/

body {
  background-color: #141416;
  margin: 0;

  /* base font */
  font-family: 'DM Sans', sans-serif;
  font-size: 12px;
  color: #ffffff;
}

button {
  font-family: 'Rubik', sans-serif;

  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
}
